import Immutable from 'seamless-immutable';

import { acceptanceTerm } from '~/api';
import i18n from '~/common/helpers/i18n';

const initialState = Immutable({
  modal: false,
  loading: false,
  checked: false,
  result: null,
  success: null
});

const eulaModel = {
  name: 'eula',
  state: initialState,
  reducers: {
    setModal(state, value) {
      return state.merge({
        modal: value
      });
    },
    setResult(state, value) {
      return state.merge({
        result: value
      });
    },
    setError(state, value) {
      return state.merge({
        error: value
      });
    },
    setLoading(state, value) {
      return state.merge({
        loading: value
      });
    },
    setCheck(state, value) {
      return state.merge({
        checked: value
      });
    },
    success(state, payload) {
      return state.merge({
        success: payload
      });
    },
    setTerm(state, result) {
      return state.merge({ result });
    },
    setTermResponse(state, result) {
      return state.merge({ result });
    }
  },
  effects: dispatch => ({
    async getLatest(params, state) {
      dispatch.eula.setLoading(true);
      const termType = params?.termType || 'eula';
      try {
        const { data } = await acceptanceTerm.getLatest({
          realm: state?.application?.realm?.realm,
          termType
        });
        dispatch.eula.setTerm(data?.termAcceptanceLatest);
        if (!data?.termAcceptanceLatest) return;

        const {
          lastPostponementDate,
          acceptanceDate,
          refusalDate,
          expired
        } = data?.termAcceptanceLatest;

        if (
          (!lastPostponementDate && !acceptanceDate && !refusalDate) ||
          expired
        ) {
          dispatch.eula.setModal(true);
        }
      } catch (e) {
        params?.onError?.(e);
      } finally {
        dispatch.eula.setLoading(false);
      }
    },
    async response({ response, onSuccess, onError }, state) {
      dispatch.eula.setLoading(true);
      try {
        const { data } = await acceptanceTerm.response({
          realm: state?.application?.realm?.realm,
          id: state?.acceptanceTerm?.result?.id,
          response
        });
        dispatch.acceptanceTerm.setTermResponse(data?.termAcceptanceResponse);
        if (onSuccess) {
          onSuccess();
        }
      } catch (e) {
        dispatch.snackbar.create({
          text: i18n.t('scenes.eula.labels.eula_error_message'),
          action: {
            label: i18n.t('action.ok'),
          }
        });
        if (onError) {
          onError();
        }
      } finally {
        dispatch.eula.setLoading(false);
      }
    },
    async onAccept(params, state) {
      dispatch.eula.setModal(false);
      dispatch.eula.response({
        response: 'accept',
        onSuccess: () => {
          if (state?.acceptanceTerm?.result?.expired && !state?.acceptanceTerm?.result?.acceptanceDate) {
            dispatch.authentication.signOut();
          }
        },
        onError: () => {
          dispatch.eula.setModal(true);
        }
      });
    },
    async onPostpone(params, state) {
      dispatch.eula.response({
        response: 'postpone',
        onSuccess: () => {
          dispatch.eula.setModal(false);
          dispatch.snackbar.create({
            text: i18n.t('scenes.eula.labels.license_term_alert'),
            action: {
              label: i18n.t('action.ok'),
            }
          });
        }
      });
    },
    async onRefuse(params, state) {
      dispatch.eula.response({
        response: 'refuse',
        onSuccess: () => {
          dispatch.eula.setModal(false);
          dispatch.authentication.signOut();
        },
        onError: () => {
          dispatch.eula.setModal(true);
        }
      });
    },
    async onTryagain(params, state) {
      dispatch.acceptanceTerm.getLatest();
    },
    async setChecked(check) {
      dispatch.eula.setCheck(check);
    }
  }),
  logics: [
    {
      type: ['eula/getLatest'],
      latest: true,
      process(_, dispatch, done) {
        dispatch.eula.setLoading({
          value: true
        });
        done();
      }
    },
    {
      type: ['eula/setError'],
      latest: true,
      process(_, dispatch, done) {
        dispatch.eula.setLoading({
          value: false
        });
        dispatch.snackbar.create({
          text: i18n.t('common.messages.error.eula'),
          action: {
            label: i18n.t('action.ok')
          }
        });
        done();
      }
    },
    {
      type: ['eula/setResult'],
      latest: true,
      process(_, dispatch, done) {
        dispatch.eula.setLoading({
          value: false
        });
        dispatch.eula.setModal(false);
        dispatch.snackbar.create({
          text: i18n.t('common.messages.success.eula'),
          action: {
            label: i18n.t('action.ok'),
          }
        });
        done();
      }
    },
    {
      type: 'eula/success',
      latest: true,
      process(_, dispatch, done) {
        dispatch.eula.setModal(false);
        done();
      }
    }
  ]
};

export default eulaModel;
