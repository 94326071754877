import { Button, IconButton } from '@digibee/beehive-ui';
import { choose, iff, otherwise, when } from '@digibee/control-statements';
import { faCheck, faSend, faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMachine } from '@xstate/react';
import { useRef } from 'react';
import { useSize } from 'react-use';

import * as Elements from './AIChat.elements';
import aiChatMachine, { ChatType } from './AIChat.machine';
import Message from './components/Message/Message';
import ResizableTextField from './components/ResizableTextField/ResizableTextField';
import Select from './components/Select/Select';
import { GlobalSidePanelConfig } from '../GlobalSidePanel/GlobalSidePanel';

import featureFlagConstants from '~/common/helpers/featureFlagConstants';
import i18n from '~/common/helpers/i18n';
import useFeatureFlag from '~/common/hooks/useFeatureFlag';
import {
  sendToBus,
  useRegisterActorOnEventBus
} from '~/scenes/Build/components/Bus';

function AIChat() {
  const { AI_NEW_PIPEGEN_MODEL } = featureFlagConstants;
  const { treatments } = useFeatureFlag([AI_NEW_PIPEGEN_MODEL]);

  const isNewAiModelEnabled =
    treatments[AI_NEW_PIPEGEN_MODEL].treatment === 'on';

  const chatContentRef = useRef<HTMLDivElement | null>(null);
  const detachedChatContentRef = useRef<HTMLDivElement | null>(null);
  const [state, send, actor] = useMachine(aiChatMachine, {
    context: {
      selectedChatType: isNewAiModelEnabled
        ? 'pipeline-assistant'
        : 'pipeline-generator'
    },
    actions: {
      scrollDown: () => {
        setTimeout(() => {
          const scrollElement = state.context.detachedChat
            ? detachedChatContentRef.current
            : chatContentRef.current;

          scrollElement?.scrollTo({
            top: scrollElement.scrollHeight,
            behavior: 'instant'
          });
        }, 100);
      }
    }
  });

  useRegisterActorOnEventBus(actor);

  const handlePromptTextChange = (text: string) => {
    send({ type: 'TYPE', text });
  };

  const handleSendMessage = () => {
    send({ type: 'SEND_MESSAGE' });
  };

  const handleRetry = () => {
    send({ type: 'RETRY' });
  };

  const [sized, { height }] = useSize(() => (
    <Elements.ChatFieldWrapper>
      <Elements.PromptFormWrapper>
        {!state.context.detachedChat && (
          <Select
            onChange={(value: string) => {
              send({ type: 'SET_CHAT_TYPE', chatType: value as ChatType });
            }}
            value={state.context.selectedChatType}
            options={[
              {
                label: i18n.t('label.ai_assistant_flow_generator'),
                value: isNewAiModelEnabled
                  ? 'pipeline-assistant'
                  : 'pipeline-generator'
              },
              {
                label: i18n.t('label.ai_assistant_jolt_generator'),
                value: 'jolt-assistant'
              },
              { label: i18n.t('label.ai_assistant_qa'), value: 'ai-assistant' }
            ]}
          />
        )}

        {choose(
          when(state.context.selectedChatType === 'jolt-assistant', () => (
            <>
              <ResizableTextField
                value={state.context.joltgenPrompt.input}
                onChange={input => send({ type: 'TYPE', input })}
                rows={2}
                placeholder={i18n.t('label.ai_jolt_generator_input')}
                withError={state.context.joltgenPrompt.invalidInput}
              />
              <ResizableTextField
                value={state.context.joltgenPrompt.output}
                onChange={output => send({ type: 'TYPE', output })}
                rows={2}
                placeholder={i18n.t('label.ai_jolt_generator_output')}
                withError={state.context.joltgenPrompt.invalidOutput}
              />
            </>
          )),
          otherwise(() => (
            <ResizableTextField
              value={state.context.prompt}
              onChange={handlePromptTextChange}
              placeholder={i18n.t('label.ai_assistant_input_placeholder')}
            />
          ))
        )}
      </Elements.PromptFormWrapper>
      <Elements.ChatActionWrapper>
        <Button size='medium' onClick={handleSendMessage}>
          <FontAwesomeIcon icon={faSend} />
        </Button>
      </Elements.ChatActionWrapper>
    </Elements.ChatFieldWrapper>
  ));

  return (
    <Elements.ChatWrapper>
      {state.context.detachedChat && <Elements.Backdrop />}
      <Elements.ChatContent
        innerRef={(node: HTMLDivElement) => {
          chatContentRef.current = node;
        }}
      >
        <Elements.DocumentationWrapper>
          <Elements.Title>
            {i18n.t('label.ai_assistant_how_to_use_title')}
          </Elements.Title>
          <Elements.Description>
            {i18n.t('label.ai_assistant_how_to_use_text')}
            <br />
            {i18n.t('label.ai_assistant_how_to_use_examples_title')}
          </Elements.Description>
          <Message
            noImage
            text={i18n.t('label.ai_assistant_how_to_use_example_1')}
          />
          <Message
            noImage
            text={i18n.t('label.ai_assistant_how_to_use_example_2')}
          />
        </Elements.DocumentationWrapper>
        <Elements.Divider />
        <Elements.MessagesWrapper>
          {state.context.history.map(chat => (
            <Elements.ConversationWrapper>
              {chat.history.map(message => (
                <Message
                  text={message.text}
                  sender={message.sender}
                  chatType={chat.type}
                  data={message.data}
                />
              ))}
              {iff(!chat.hasEnded && state.matches('sending'), () => (
                <Message loading />
              ))}
              {iff(!chat.hasEnded && state.matches('error'), () => (
                <Message isError onRetry={handleRetry} />
              ))}
            </Elements.ConversationWrapper>
          ))}
        </Elements.MessagesWrapper>

        {state.context.detachedChat && (
          <Elements.DetachedChat offset={height}>
            <Elements.DetachedChatHeader>
              <Elements.DetachedChatTitle>
                {choose(
                  when(
                    ['pipeline-generator', 'pipeline-assistant'].includes(
                      state.context.detachedChat.type
                    ),
                    () => i18n.t('label.ai_assistant_flow_generator')
                  ),
                  when(
                    state.context.detachedChat.type === 'jolt-assistant',
                    () => i18n.t('label.ai_assistant_jolt_generator')
                  ),
                  when(state.context.detachedChat.type === 'ai-assistant', () =>
                    i18n.t('label.ai_assistant_qa')
                  )
                )}
              </Elements.DetachedChatTitle>
              <Elements.DetachedChatActions>
                <IconButton
                  data-testid='global-panel-close-button'
                  onClick={() => send({ type: 'CLEAR_DETACHED_CHAT' })}
                  css={{
                    width: 32,
                    height: 32
                  }}
                >
                  <FontAwesomeIcon size='lg' icon={faTrash} />
                </IconButton>
              </Elements.DetachedChatActions>
              <Elements.DetachedFinishAction>
                <IconButton
                  data-testid='global-panel-close-button'
                  onClick={() => send({ type: 'END_CHAT' })}
                  css={{
                    width: 32,
                    height: 32
                  }}
                >
                  <FontAwesomeIcon size='lg' icon={faCheck} />
                </IconButton>
              </Elements.DetachedFinishAction>
            </Elements.DetachedChatHeader>
            <Elements.DetachedChatContent
              innerRef={(node: HTMLDivElement) => {
                detachedChatContentRef.current = node;
              }}
            >
              <Elements.ConversationWrapper>
                {state.context.detachedChat?.history.map(message => (
                  <Message
                    text={message.text}
                    sender={message.sender}
                    data={message.data}
                    chatType={state.context.detachedChat?.type}
                  />
                ))}
                {iff(
                  !state.context.detachedChat.hasEnded &&
                    state.matches('sending'),
                  () => (
                    <Message loading />
                  )
                )}
                {iff(
                  !state.context.detachedChat.hasEnded &&
                    state.matches('error'),
                  () => (
                    <Message isError onRetry={handleRetry} />
                  )
                )}
              </Elements.ConversationWrapper>
            </Elements.DetachedChatContent>
          </Elements.DetachedChat>
        )}
      </Elements.ChatContent>
      {sized}
    </Elements.ChatWrapper>
  );
}

const AIChatActions = () => (
  <IconButton
    data-testid='global-panel-close-button'
    onClick={() => sendToBus({ to: 'AIChat', event: { type: 'CLEAR' } })}
    css={{
      width: 32,
      height: 32
    }}
  >
    <FontAwesomeIcon size='lg' icon={faTrash} />
  </IconButton>
);

const aiChatPanelConfig: GlobalSidePanelConfig = {
  name: 'AIChat',
  title: i18n.t('label.ai_assistant'),
  component: <AIChat />,
  actionsSlot: <AIChatActions />,
  isBeta: true
};

export default aiChatPanelConfig;
