/* eslint-disable radix */
/* eslint-disable react/jsx-no-undef */
import {
  Box,
  Checkbox,
  Button as DSButton,
  Input,
  InputNumber,
  Select,
  SelectAsync,
  SideSheet
} from '@digibee/beehive-ui';
import { iff } from '@digibee/control-statements';
import PropTypes from 'prop-types';

import ValidationPropTypes from './common/ValidationPropTypes';
import DeploymentAlertV2 from './components/DeploymentAlert/DeploymentAlertV2';
import DeployPipelineDialogV2 from './components/DeployPipelineDialog/DeployPipelineDialogV2';
import DescriptionHelperV2 from './components/DescriptionHelper/DescriptionHelpeV2';
import PipelineToRedeployV2 from './components/PipelineToRedeploy/PipelineToRedeployV2';
import SizePipeline from './components/SizePipeline';
import * as Elements from './DeployPipeline.elements';
import enhancer from './OldDeployPipeline.enhancer';
import ConfirmPromoteV2 from '../ConfirmPromote/ConfirmPromoteV2';

import getFieldError from '~/common/helpers/getFieldError';
import i18n from '~/common/helpers/i18n';
import Slider from '~/components/Slider';
import Switch from '~/components/Switch';

const helperMarksCustom = max => {
  const getLabel = index => {
    if (index === 0) {
      return 'min. 1';
    }
    if (max === index + 1) {
      return `max. ${max}`;
    }
    return '';
  };
  return Array.from({ length: max }, (_, index) => ({
    value: index + 1,
    label: getLabel(index)
  }));
};

const OldDeployPipeline = props => {
  const {
    configurations,
    loadPipelines,
    hasAlert,
    validationLicense,
    validationPipeline,
    configurationLastDeploy,
    onChangePipeline,
    versionMinor,
    optionVersionEngine,
    onChangePipelineVersion,
    onChangeConfiguration,
    onChangeDeletePipe,
    sliderProps,
    onChangeConsumer,
    onChangeReplicas,
    onChangeReplicasDeploy,
    loading,
    replicasDeploy,
    trigger,
    editPipelinePath,
    pipelineValidationError,
    form,
    showPromote,
    enviromentsOptions,
    onChangeEnvironment,
    onChangePipelineVersionEngine,
    openPromote,
    deployment,
    closePromote,
    newDeployment,
    pipelineToPromoteConfirm,
    loadingPromote,
    texts,
    onCloseClick,
    showConfirmDialog,
    closeDialog,
    newDeploy,
    confirmDeploy,
    triggers,
    isPipelineEngine
  } = props;

  return (
    <SideSheet
      open
      onClose={() => onCloseClick()}
      header={i18n.t(texts.title)}
      subHeader={
        showPromote && i18n.t('label.before_promoting_pipe_explanation')
      }
      size='small'
    >
      <>
        <form onSubmit={form.handleSubmit}>
          <SideSheet.Section>
            <Box css={{ gap: '$3', display: 'flex', flexDirection: 'column' }}>
              <SelectAsync
                isClearable
                cacheOptions
                data-testid='run-create-title-field-pipeline'
                variant='async-paginate'
                placeholder={i18n.t('scenes.runtime.labels.pipeline_singular')}
                value={form.values.pipeline}
                loadOptions={loadPipelines}
                onChange={value => onChangePipeline(value)}
                name='pipeline'
                helperText={i18n.t(
                  getFieldError(form.touched, form.errors, 'pipeline.value')
                )}
                additional={{
                  page: -1,
                  searchPage: -1
                }}
                label={i18n.t(
                  'scenes.metrics.labels.upper_and_capitalize.capitalized',
                  {
                    item: i18n.t('label.pipeline')
                  }
                )}
                className='fs-mask'
                debounceTimeout={500}
                isDisabled={showPromote}
              />
              <Select
                label={i18n.t('scenes.runtime.labels.version')}
                options={versionMinor}
                onChange={value => onChangePipelineVersion(value)}
                name='version'
                value={form.values.version}
                data-testid='run-create-title-field-version'
                isDisabled={showPromote}
                helperText={i18n.t(
                  getFieldError(form.touched, form.errors, 'version.value')
                )}
                placeholder={i18n.t('scenes.runtime.labels.version')}
              />
              <Input
                css={{ marginBottom: '$2' }}
                disabled
                placeholder={i18n.t('noun.project')}
                label={i18n.t('noun.project')}
                about=''
                name='project'
                value={form.values.projectName}
                data-testid='run-create-title-field-project'
              />
              {showPromote && (
                <>
                  <Select
                    name='environment'
                    onChange={data => {
                      onChangeEnvironment({
                        newEnv: data,
                        replicas: form?.values?.replicas,
                        size: form?.values?.configuration
                      });
                    }}
                    helperText={i18n.t(
                      'label.select_env_to_promote_pipe_to_assist'
                    )}
                    placeholder={i18n.t(
                      'label.select_the_environment_placeholder'
                    )}
                    options={enviromentsOptions}
                    data-testid='run-environment-select'
                    value={form.values.environment}
                  />
                  <Box css={{ display: 'flex' }}>
                    <Checkbox
                      checked={form.values.deletePipe}
                      onClick={() =>
                        onChangeDeletePipe(!form.values.deletePipe)
                      }
                      data-testid='run-checkbox-delete'
                      label={i18n.t('label.delete_pipe_from_previous_env', {
                        environmentName:
                          deployment?.activeConfiguration?.environment?.name
                      })}
                    />
                  </Box>
                </>
              )}
              {iff(replicasDeploy.length, () => (
                <Box top={20} right={16} left={16}>
                  <Select
                    data-testid='run-create-title-field-replicasDeploy'
                    isMargin={false}
                    options={replicasDeploy}
                    type='select'
                    className='fs-mask'
                    helperText={i18n.t(
                      getFieldError(
                        form.touched,
                        form.errors,
                        'replicasDeploy.value'
                      )
                    )}
                    label={i18n.t('scenes.runtime.labels.instance')}
                    placeholder={i18n.t('scenes.runtime.labels.instance')}
                    onChange={value =>
                      onChangeReplicasDeploy({
                        ...form.values,
                        pipelineId: form?.values?.version?.value,
                        replicasDeploy: value
                      })
                    }
                    name='replicasDeploy'
                    value={form.values.replicasDeploy}
                  />
                </Box>
              ))}
              {iff(configurationLastDeploy, () => (
                <Elements.Box top={20}>
                  <PipelineToRedeployV2
                    trigger={trigger}
                    title={i18n.t('scenes.runtime.labels.current_deploy')}
                    deployment={configurationLastDeploy}
                    editPipelinePath={editPipelinePath}
                  />
                </Elements.Box>
              ))}
            </Box>
          </SideSheet.Section>
          {form.values?.pipeline?.value &&
            form.values?.version.value &&
            isPipelineEngine && (
              <>
                <SideSheet.Section
                  title={
                    <Box css={{ display: 'flex', gap: '$2' }}>
                      {i18n.t('label.pipeline_engine_version')}{' '}
                    </Box>
                  }
                >
                  <Box
                    css={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <DescriptionHelperV2
                      text={i18n.t(
                        'label.pipeline_engine_version_explanation_select'
                      )}
                      linkText={i18n.t('action.learn_more')}
                      link={i18n.t('action.pipe_engine_vers_doc_url')}
                    />
                  </Box>
                  <Box css={{ marginBottom: '$2' }} />
                  <Box
                    css={{
                      gap: '$3',
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <Select
                      label={i18n.t(
                        'scenes.runtime.labels.select_engine_version'
                      )}
                      options={optionVersionEngine}
                      onChange={versionEngine =>
                        onChangePipelineVersionEngine(versionEngine)
                      }
                      name='version'
                      value={optionVersionEngine.find(
                        option => option.value == form.values?.isPipelineEngine
                      )}
                      data-testid='run-create-title-field-version-select'
                    />
                  </Box>
                </SideSheet.Section>
              </>
            )}
          {iff(form.values?.pipeline?.value, () => (
            <>
              <SideSheet.Section
                title={i18n.t('scenes.runtime.labels.pipeline_size')}
              >
                <Box>
                  <DescriptionHelperV2
                    text={i18n.t(
                      'scenes.runtime.labels.pipeline_size_explanation'
                    )}
                    linkText={i18n.t('scenes.runtime.labels.know_more')}
                    link={i18n.t('scenes.runtime.actions.news_url')}
                  />
                  <Box css={{ marginTop: '$2' }}>
                    <SizePipeline
                      loading={!configurations?.length}
                      configurations={configurations}
                      value={form.values?.configuration}
                      name='configuration'
                      onChange={(event, value) =>
                        onChangeConfiguration(event, {
                          ...form.values,
                          pipelineId: form?.values?.version?.value,
                          replicas: form?.values?.replicas,
                          value
                        })
                      }
                    />
                  </Box>
                </Box>
              </SideSheet.Section>

              {iff(form?.values?.configuration, () => (
                <>
                  <SideSheet.Section
                    title={i18n.t(
                      'scenes.runtime.labels.concurrent_executions'
                    )}
                  >
                    <Box>
                      <DescriptionHelperV2
                        text={i18n.t(
                          'scenes.runtime.labels.concurrent_executions_explanation'
                        )}
                        linkText={i18n.t('scenes.runtime.labels.know_more')}
                        link={i18n.t('scenes.runtime.actions.news_url')}
                      />
                      <Box css={{ marginTop: '$2' }}>
                        <Slider
                          step={1}
                          min={1}
                          max={sliderProps.max}
                          name='consumer'
                          marks={helperMarksCustom(sliderProps.max)}
                          onChange={(event, value) =>
                            onChangeConsumer(event, value)
                          }
                          isInput
                          value={form.values.consumer}
                        />
                      </Box>
                    </Box>
                  </SideSheet.Section>

                  <SideSheet.Section
                    title={i18n.t('scenes.runtime.labels.replicas')}
                  >
                    <Box>
                      <DescriptionHelperV2
                        text={i18n.t(
                          'scenes.runtime.labels.replicas_explanation'
                        )}
                        linkText={i18n.t('scenes.runtime.labels.know_more')}
                        link={i18n.t('scenes.runtime.actions.news_url')}
                      />

                      <Box css={{ marginTop: '$3' }}>
                        <InputNumber
                          placeholder={i18n.t(
                            'scenes.runtime.labels.replicas_placeholder'
                          )}
                          label={i18n.t(
                            'scenes.runtime.labels.replicas_placeholder'
                          )}
                          step
                          onChange={value => {
                            if (parseInt(value) < 99999999) {
                              onChangeReplicas({
                                ...form.values,
                                pipelineId: form?.values?.version.value,
                                configurationId: form?.values?.configuration,
                                value: parseInt(value)
                              });
                            }
                          }}
                          name='replicas'
                          data-testid='run-create-title-field-replicas'
                          min={1}
                          value={form?.values?.replicas}
                        />
                      </Box>
                    </Box>
                  </SideSheet.Section>

                  <Box>
                    <DeploymentAlertV2
                      hasAlert={hasAlert}
                      configurationLastDeploy={!!configurationLastDeploy}
                      validationLicense={validationLicense}
                      validationPipeline={validationPipeline}
                    />
                  </Box>
                </>
              ))}
            </>
          ))}{' '}
          <SideSheet.Footer>
            <DSButton
              disabled={
                !!hasAlert ||
                !!pipelineValidationError?.length ||
                !!validationLicense?.length
              }
              loading={loading}
              onClick={form?.submitForm}
              data-testid={texts.datatestidText}
              type='primary'
            >
              {i18n.t(texts.button)}
            </DSButton>
          </SideSheet.Footer>
        </form>
      </>
      {showConfirmDialog && (
        <DeployPipelineDialogV2
          onClose={closeDialog}
          texts={texts}
          dataModal={newDeploy}
          confirmDeploy={confirmDeploy}
        />
      )}
      {openPromote && showPromote && (
        <ConfirmPromoteV2
          loadingPromote={loadingPromote}
          onClose={closePromote}
          dataModal={newDeployment}
          oldDeployment={deployment}
          pipelineToPromoteConfirm={pipelineToPromoteConfirm}
          isDeletePipe={form.values.deletePipe}
          triggers={triggers}
        />
      )}
    </SideSheet>
  );
};

OldDeployPipeline.defaultProps = {
  onChangePipeline: () => {},
  onChangePipelineVersion: () => {},
  onChangeConfiguration: () => {},
  onChangeConsumer: () => {},
  onChangeReplicas: () => {},
  hasAlert: false,
  loading: false,
  configurations: null,
  loadPipelines: () => {},
  onChangeReplicasDeploy: () => {},
  versionMinor: null,
  replicasDeploy: null,
  trigger: null,
  configurationLastDeploy: null,
  validationLicense: null,
  validationPipeline: null,
  editPipelinePath: '',
  sliderProps: {
    max: 0
  },
  pipelineValidationError: false,
  form: null,
  showPromote: false,
  enviromentsOptions: [],
  onChangeEnvironment: () => {},
  openPromote: false,
  deployment: null,
  closePromote: () => {},
  newDeployment: null,
  pipelineToPromoteConfirm: () => {},
  loadingPromote: false,
  onChangeDeletePipe: () => {},
  onCloseClick: () => {},
  showConfirmDialog: false,
  closeDialog: () => {},
  newDeploy: {},
  confirmDeploy: () => {},
  isPipelineEngine: false
};

OldDeployPipeline.propTypes = {
  hasAlert: PropTypes.bool,
  configurationLastDeploy: PropTypes.objectOf({}),
  validationLicense: PropTypes.arrayOf(ValidationPropTypes),
  validationPipeline: PropTypes.arrayOf(ValidationPropTypes),
  configurations: PropTypes.arrayOf(SizePipeline.propTypes.configurations),
  loadPipelines: PropTypes.func,
  onChangePipeline: PropTypes.func,
  onChangePipelineVersion: PropTypes.func,
  onChangeConfiguration: PropTypes.func,
  onChangeConsumer: PropTypes.func,
  onChangeReplicas: PropTypes.func,
  onChangeReplicasDeploy: PropTypes.func,
  versionMinor: PropTypes.arrayOf([]),
  replicasDeploy: PropTypes.arrayOf([]),
  optionVersionEngine: PropTypes.arrayOf([]),
  loading: PropTypes.bool,
  trigger: PropTypes.objectOf({}),
  editPipelinePath: PropTypes.string,
  sliderProps: PropTypes.objectOf({
    max: PropTypes.number
  }),
  pipelineValidationError: PropTypes.bool,
  form: PropTypes.objectOf({}),
  showPromote: PropTypes.bool,
  enviromentsOptions: PropTypes.arrayOf([]),
  onChangeEnvironment: PropTypes.func,
  onChangePipelineVersionEngine: PropTypes.func,
  openPromote: PropTypes.bool,
  deployment: PropTypes.objectOf({}),
  closePromote: PropTypes.func,
  newDeployment: PropTypes.objectOf({}),
  pipelineToPromoteConfirm: PropTypes.func,
  loadingPromote: PropTypes.bool,
  onChangeDeletePipe: PropTypes.func,
  texts: PropTypes.objectOf({}).isRequired,
  onCloseClick: PropTypes.func,
  showConfirmDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  newDeploy: PropTypes.objectOf({}),
  confirmDeploy: PropTypes.func,
  triggers: PropTypes.objectOf({}).isRequired,
  isPipelineEngine: PropTypes.bool
};

export default enhancer(OldDeployPipeline);
